
export default {
  name: 'NuxtError',
  // layout: 'default', // optional
  components: {
    Error404: () => import('@/components/core/404'),
  },
  props: {
    error: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    errorPage() {
      return this.Error404
    },
  },
}
