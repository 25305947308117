
import { mapGetters } from 'vuex'

export default {
  data: () => ({
    links: [{ name: 'Beranda', Link: '/#' }],
    appName: 'SiTrendi - Sistem Pesantren Digital',
  }),
  computed: {
    ...mapGetters(['isAuthenticated', 'loggedInUser']),
  },
}
