
export default {
  middleware: 'auth',
  components: {
    CoreAppBar: () => import('@/components/core/guest/AppBar'),
    CoreView: () => import('@/components/core/guest/View'),
    CoreFooter: () => import('@/components/core/guest/Footer'),
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    source: String,
  },
  data: () => ({
    drawer: null,
  }),
  head: {
    bodyAttrs: {
      class: 'hold-transition layout-top-nav',
    },
  },
}
