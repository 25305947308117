import Vue from 'vue';

import {
  LayoutPlugin,
  FormPlugin,
  FormCheckboxPlugin,
  FormInputPlugin,
  FormRadioPlugin,
  ToastPlugin,
  ModalPlugin,
  IconsPlugin,
  VBPopoverPlugin,
  VBTooltipPlugin,
  VBScrollspyPlugin,
  BContainer,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BModal,
  BIcon,
  BIconCalendar,
  BNavbar,
  BNavbarNav,
  BNavForm,
  BNavItem,
  BNavItemDropdown,
  BDropdownItem,
  BNavbarBrand,
  BNavbarToggle,
  BCollapse,
  BCard,
  BCardBody,
  BCardGroup,
  BCardText,
  BDropdown,
  BDropdownDivider,
  BForm,
  BFormFile,
  BFormTextarea,
  BFormGroup,
  BPagination,
  BLink,
  BImg,
  BInputGroup,
  BInputGroupText,
  BInputGroupAppend,
  BTab,
  BTabs,
  BFormSelect,
  BFormDatepicker,
  BCarousel,
  BCarouselSlide,
  VBModal,
  VBPopover,
  VBTooltip,
  VBScrollspy
} from 'bootstrap-vue';

Vue.use(LayoutPlugin);
Vue.use(FormPlugin);
Vue.use(FormCheckboxPlugin);
Vue.use(FormInputPlugin);
Vue.use(FormRadioPlugin);
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);
Vue.use(IconsPlugin);

Vue.use(VBPopoverPlugin);
Vue.use(VBTooltipPlugin);
Vue.use(VBScrollspyPlugin);

Vue.component('BContainer', BContainer);
Vue.component('BRow', BRow);
Vue.component('BCol', BCol);
Vue.component('BFormInput', BFormInput);
Vue.component('BButton', BButton);
Vue.component('BTable', BTable);
Vue.component('BModal', BModal);
Vue.component('BIcon', BIcon);
Vue.component('BIconCalendar', BIconCalendar);
Vue.component('BNavbar', BNavbar);
Vue.component('BNavbarNav', BNavbarNav);
Vue.component('BNavForm', BNavForm);
Vue.component('BNavItem', BNavItem);
Vue.component('BNavItemDropdown', BNavItemDropdown);
Vue.component('BDropdownItem', BDropdownItem);
Vue.component('BNavbarBrand', BNavbarBrand);
Vue.component('BNavbarToggle', BNavbarToggle);
Vue.component('BCollapse', BCollapse);
Vue.component('BCard', BCard);
Vue.component('BCardBody', BCardBody);
Vue.component('BCardGroup', BCardGroup);
Vue.component('BCardText', BCardText);
Vue.component('BDropdown', BDropdown);
Vue.component('BDropdownDivider', BDropdownDivider);
Vue.component('BForm', BForm);
Vue.component('BFormFile', BFormFile);
Vue.component('BFormTextarea', BFormTextarea);
Vue.component('BFormGroup', BFormGroup);
Vue.component('BPagination', BPagination);
Vue.component('BLink', BLink);
Vue.component('BImg', BImg);
Vue.component('BInputGroup', BInputGroup);
Vue.component('BInputGroupText', BInputGroupText);
Vue.component('BInputGroupAppend', BInputGroupAppend);
Vue.component('BTab', BTab);
Vue.component('BTabs', BTabs);
Vue.component('BFormSelect', BFormSelect);
Vue.component('BFormDatepicker', BFormDatepicker);
Vue.component('BCarousel', BCarousel);
Vue.component('BCarouselSlide', BCarouselSlide);

Vue.directive('BModal', VBModal);
Vue.directive('BPopover', VBPopover);
Vue.directive('BTooltip', VBTooltip);
Vue.directive('BScrollspy', VBScrollspy);
