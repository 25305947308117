
export default {
  name: 'MaterialCardTable',
  inheritAttrs: false,
  props: {
    url: {
      type: String,
      default: '',
    },
    showjumlahbukubesar: {
      type: Boolean,
      default: false,
    },
    showjumlahneraca: {
      type: Boolean,
      default: false,
    },
    activetextbutton: {
      type: String,
      default: null,
    },
    showcreatewithidbutton: {
      type: Boolean,
      default: false,
    },
    urlcreate: {
      type: String,
      default: '/',
    },
    viewqr: {
      type: Boolean,
      default: false,
    },
    viewqrurl: {
      type: String,
      default: '/',
    },
    urlexport: {
      type: String,
      default: '/',
    },
    urlgenerate: {
      type: String,
      default: '/',
    },
    urlwagenerate: {
      type: String,
      default: '/',
    },
    urlupload: {
      type: String,
      default: '/',
    },
    urlimport: {
      type: String,
      default: '/',
    },
    urlrekening: {
      type: String,
      default: '/',
    },
    urlbackbutton: {
      type: String,
      default: '/',
    },
    urlgeneraterekening: {
      type: String,
      default: '/',
    },
    urlsubdata: {
      type: String,
      default: '/',
    },
    color: {
      type: String,
      default: 'secondary',
    },
    title: {
      type: String,
      default: undefined,
    },
    customtitleleft: {
      type: String,
      default: undefined,
    },
    customtitleright: {
      type: String,
      default: undefined,
    },
    text: {
      type: String,
      default: undefined,
    },
    fields: {
      type: Array,
      default: null,
    },
    fieldsdetail: {
      type: Array,
      default: null,
    },
    subdatatext: {
      type: String,
      default: null,
    },
    showprint: {
      type: Boolean,
      default: false,
    },
    showsubdata: {
      type: Boolean,
      default: false,
    },
    showaction: {
      type: Boolean,
      default: false,
    },
    showcreatebutton: {
      type: Boolean,
      default: false,
    },
    showeditbutton: {
      type: Boolean,
      default: false,
    },
    showdeletebutton: {
      type: Boolean,
      default: false,
    },
    showrekeningbutton: {
      type: Boolean,
      default: false,
    },
    showgeneraterekeningbutton: {
      type: Boolean,
      default: false,
    },
    showfilterbutton: {
      type: Boolean,
      default: false,
    },
    showbackbutton: {
      type: Boolean,
      default: false,
    },
    showactivebutton: {
      type: Boolean,
      default: false,
    },
    showstatusbutton: {
      type: Boolean,
      default: false,
    },
    isfiltered: {
      type: Boolean,
      default: false,
    },
    isuploaded: {
      type: Boolean,
      default: false,
    },
    isexported: {
      type: Boolean,
      default: false,
    },
    showgeneratecode: {
      type: Boolean,
      default: false,
    },
    showwageneratecode: {
      type: Boolean,
      default: false,
    },
    showpagination: {
      type: Boolean,
      default: false,
    },
    filters: {
      type: Boolean,
      default: false,
    },
    filterdatas: {
      type: Object,
      default: null,
    },
    variablefilter: {
      type: String,
      default: null,
    },
    exportfilename: {
      type: String,
      default: null,
    },
    topheader: {
      type: Boolean,
      default: false,
    },
    dataTopHeader: {
      type: Array,
      default: null,
    },
    showsppcard: {
      type: Function,
      default: null,
    },
    customNominal: {
      type: Boolean,
      default: false,
    },
    showfullcode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isBusy: false,
      search: '',
      items: [],
      total_debit: 0,
      total_kredit: 0,
      saldo_awal: 0,
      saldo_akhir: 0,
      total_saldo_awal: 0,
      total_saldo_akhir: 0,
      firstLoad: false,
      modalQR: {
        show: false,
        url: null,
        title: null,
        class: 'qr_iframe',
      },
      meta: {
        count: 0,
        limit: 10,
        next_page: 1,
        page: 1,
        previous_page: 1,
        total_pages: 1,
      },
      pageoptions: [10, 20, 50],
    }
  },
  computed: {
    hasOffset() {
      return this.$slots.header || this.$slots.offset || this.title || this.text
    },
    styles() {
      if (!this.hasOffset) return null
      return {
        marginBottom: `${this.offset}px`,
        marginTop: `${this.offset * 2}px`,
      }
    },
  },
  mounted() {
    this.getData()
  },
  methods: {
    showModol() {
      alert('tes')
    },
    printData() {
      const w = window.open()
      w.document.write(this.$refs.PrintData.$el.innerHTML)
      w.document.close()
      w.setTimeout(function () {
        w.print()
      }, 1000)
    },
    async generateCode() {
      await this.$axios
        .get('api/' + this.urlgenerate, {
          responseType: 'arraybuffer',
        })
        .then((res) => {
          const FILE = window.URL.createObjectURL(
            new Blob([res.data], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            })
          )
          const docUrl = document.createElement('a')
          docUrl.href = FILE
          docUrl.setAttribute('download', 'kode_aktivasi_wali_santri.xlsx')
          document.body.appendChild(docUrl)
          docUrl.click()
        })
        .catch((res) => {
          this.$swal.fire({
            icon: 'warning',
            title: res.response.data.message,
            showConfirmButton: false,
            timer: 1500,
          })
        })
    },
    async SendWaActivation() {
      await this.$swal
        .fire({
          title:
            'Apakah akan melakukan pengiriman kode aktivasi via Whatsapp ?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ya',
          cancelButtonText: 'Tidak',
        })
        .then((result) => {
          if (result.value) {
            this.$axios
              .post('api/' + this.urlwagenerate)
              .then((res) => {
                this.$swal
                  .fire({
                    icon: 'success',
                    title: 'Kode Aktivasi Berhasil dikirim ke Wali Santri.',
                    showConfirmButton: false,
                    timer: 1500,
                  })
                  .then((res) => {
                    this.getData()
                  })
              })
              .catch((res) => {
                this.$swal.fire({
                  icon: 'warning',
                  title: res.response.data.message,
                  showConfirmButton: false,
                  timer: 1500,
                })
              })
          }
        })
    },
    async generateRekening() {
      await this.$swal
        .fire({
          title: 'Apakah akan melakukan Generate Rekening ?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ya',
          cancelButtonText: 'Tidak',
        })
        .then((result) => {
          if (result.value) {
            this.$axios
              .post('api/' + this.urlgeneraterekening)
              .then((res) => {
                this.$swal
                  .fire({
                    icon: 'success',
                    title: 'Rekening berhasil digenerate.',
                    showConfirmButton: false,
                    timer: 1500,
                  })
                  .then((res) => {
                    this.getData()
                  })
              })
              .catch((res) => {
                this.$swal.fire({
                  icon: 'warning',
                  title: res.response.data.message,
                  showConfirmButton: false,
                  timer: 1500,
                })
              })
          }
        })
    },
    async exportExcel() {
      await this.$axios
        .get('api/' + this.urlexport, {
          responseType: 'arraybuffer',
        })
        .then((res) => {
          const FILE = window.URL.createObjectURL(
            new Blob([res.data], {
              type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            })
          )
          const docUrl = document.createElement('a')
          docUrl.href = FILE
          docUrl.setAttribute('download', this.exportfilename + '.xlsx')
          document.body.appendChild(docUrl)
          docUrl.click()
        })
        .catch((res) => {
          this.$swal.fire({
            icon: 'warning',
            title: res.response.data.message,
            showConfirmButton: false,
            timer: 1500,
          })
        })
    },
    async ChangeStatus(id, isActive) {
      await this.$swal
        .fire({
          title: (isActive ? 'non-aktifkan' : 'aktifkan') + ' data ini ?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ya',
          cancelButtonText: 'Tidak',
        })
        .then((result) => {
          if (result.value) {
            this.$axios
              .post('api/' + this.url + '/change-status/' + id, {
                is_active: isActive,
              })
              .then((res) => {
                this.$swal
                  .fire({
                    icon: 'success',
                    title:
                      'Data berhasil di ' +
                      (isActive ? 'non-aktifkan' : 'aktifkan'),
                    showConfirmButton: false,
                    timer: 1500,
                  })
                  .then((res) => {
                    this.getData()
                  })
              })
              .catch((res) => {
                this.$swal.fire({
                  icon: 'warning',
                  title: res.response.data.message,
                  showConfirmButton: false,
                  timer: 1500,
                })
              })
          }
        })
    },
    async ChangeStatusPPOB(id, status) {
      await this.$swal
        .fire({
          title: (status ? 'non-aktifkan' : 'aktifkan') + ' data ini ?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ya',
          cancelButtonText: 'Tidak',
        })
        .then((result) => {
          if (result.value) {
            this.$axios
              .put('api/' + this.url + '/change-status/' + id, { status })
              .then((res) => {
                this.$swal
                  .fire({
                    icon: 'success',
                    title:
                      'Data berhasil di ' +
                      (status ? 'non-aktifkan' : 'aktifkan'),
                    showConfirmButton: false,
                    timer: 1500,
                  })
                  .then((res) => {
                    this.getData()
                  })
              })
              .catch((res) => {
                this.$swal.fire({
                  icon: 'warning',
                  title: res.response.data.message,
                  showConfirmButton: false,
                  timer: 1500,
                })
              })
          }
        })
    },
    async ChangeStatusBooking(id, isActive) {
      await this.$swal
        .fire({
          title:
            (isActive ? 'non-aktifkan' : 'Implementasikan') + ' lembaga ini ?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ya',
          cancelButtonText: 'Tidak',
        })
        .then((result) => {
          if (result.value) {
            this.$axios
              .post('api/' + this.url + '/change-status/' + id, {
                is_active: isActive,
              })
              .then((res) => {
                this.$swal
                  .fire({
                    icon: 'success',
                    title:
                      'Data berhasil di ' +
                      (isActive ? 'non-aktifkan' : 'implementasikan'),
                    showConfirmButton: false,
                    timer: 1500,
                  })
                  .then((res) => {
                    this.getData()
                  })
              })
              .catch((res) => {
                this.$swal.fire({
                  icon: 'warning',
                  title: res.response.data.message,
                  showConfirmButton: false,
                  timer: 1500,
                })
              })
          }
        })
    },
    searchData() {
      this.getData(this.search)
    },
    async getData() {
      let query = '?'

      query += 'q=' + this.search
      if (this.search) {
        this.meta.page = 1
      }
      query += '&page=' + this.meta.page
      query += '&limit=' + this.meta.limit

      if (
        this.filterdatas &&
        this.filterdatas !== null &&
        this.filterdatas !== undefined
      ) {
        Object.keys(this.filterdatas).forEach((f) => {
          if (this.filterdatas[f] && this.filterdatas[f] !== undefined) {
            query += `&${f}=${this.filterdatas[f]}`
          }
        })
      }

      await this.$axios
        .get('api/' + this.url + (query !== '' ? query : ''))
        .then((res) => {
          this.isBusy = true
          this.items = []

          if (res.data.meta) {
            if (res.data.meta.count > 0 && !this.firstLoad) {
              this.firstLoad = true
            }

            this.meta.count = res.data.meta.count
          } else this.meta.count = res.data.data.length

          if (Array.isArray(res.data.data) && res.data.data != null) {
            this.items = res.data.data
          } else if (!Array.isArray(res.data.data) && res.data.data != null) {
            this.items = [res.data.data]
          } else {
            this.items = []
          }

          if (this.items !== null) {
            if (this.showjumlahbukubesar) {
              this.total_debit = res.data.total_debit
              this.total_kredit = res.data.total_kredit
              this.saldo_awal = res.data.saldo_awal
              this.saldo_akhir = res.data.saldo_akhir
            }
            if (this.showjumlahneraca) {
              this.total_debit = res.data.total_debit
              this.total_kredit = res.data.total_kredit
              this.total_saldo_awal = res.data.total_saldo_awal
              this.total_saldo_akhir = res.data.total_saldo_akhir
            }
          } else {
            this.items = []
          }
          this.isBusy = false
        })
    },
    printQr(id, name) {
      let url = window.location.origin + '/' + this.viewqrurl
      if (id !== 'all') {
        url += '&customer_id=' + id
        this.modalQR.class = 'qr_iframe'
      } else this.modalQR.class = 'qr_iframe_all'

      this.modalQR.url = url
      this.modalQR.title = name
      this.modalQR.show = true
    },
    printDivQR() {
      document.querySelector('#printerDiv > iframe').contentWindow.print()
    },
    async deleteData(id) {
      await this.$swal
        .fire({
          title: 'hapus data ini ?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ya',
          cancelButtonText: 'Tidak',
        })
        .then((result) => {
          if (result.value) {
            this.$axios.delete('api/' + this.url + '/' + id).then((res) => {
              this.$swal
                .fire({
                  icon: 'success',
                  title: 'Data berhasil di hapus',
                  showConfirmButton: false,
                  timer: 1500,
                })
                .then((res) => {
                  this.getData()
                })
            })
          }
        })
    },
  },
}
